import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import HcpdPageHero from '../../components/HealthCareProviderDirectory/HcpdPageHero'
import RegistrationForm from '../../components/Auth/RegistrationForm'
import ApolloWrapper from '../../components/Auth/ApolloWrapper'
import { ProvideAuth } from '../../components/Auth/useAuth'
import AdminRedirectHoc from '../../components/Auth/AdminRedirectHoc'

export const query = graphql`
  {
    prismicHcpdEditRegistrationPage {
      data {
        title {
          text
        }
        body_text {
          richText
        }
      }
    }
  }
`

const EditProviderRecord = ({ location, data }) => {
  const doc = data.prismicHcpdEditRegistrationPage?.data
  if (!doc) {
    return null
  }
  const userPk = location.state?.userPk
  if (!userPk) {
    return null
  }

  return (
    <ProvideAuth>
      <AdminRedirectHoc>
        <ApolloWrapper>
          <Layout>
            <HcpdPageHero
              title={doc.title?.text ?? 'Edit Registration'}
              body={doc.body_text}
            />
            <RegistrationForm user={userPk} isAdmin />
          </Layout>
        </ApolloWrapper>
      </AdminRedirectHoc>
    </ProvideAuth>
  )
}

export default EditProviderRecord
